import React, { useState } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';
import PropTypes from 'prop-types'
import styles from './styles';
import LogoC from "/public/assets/A-logo-primary.svg";
import { TweenMax, TimelineMax, Power2 } from "gsap/all";



const CoursesCards = ({ children, to, classes }) => {
    const [logoRef, setLogoRef] = useState(null)
    const [textRef, setTextRef] = useState(null)

    const theme = useTheme()
    let tl = new TimelineMax()

    const onHoverIn = () => {
        tl.fromTo(textRef, .5, { x: '0%', ease: Power2 }, { x: '-5%', ease: Power2 });
        TweenMax.to(logoRef, 0, {
            display: "flex"
        })
        tl.fromTo(logoRef, .5, { opacity: '0%', ease: Power2 }, { opacity: '100%', ease: Power2 });
    }
    const onHoverOut = () => {
        tl.fromTo(logoRef, 1, { opacity: '100%', ease: Power2 }, { opacity: '0%', ease: Power2 });
        tl.fromTo(textRef, 1, { x: '-5%', ease: Power2 }, { x: '0%', ease: Power2 });

        TweenMax.to(logoRef, 0, {
            display: "none"
        })
    }
    return (
        <Button onMouseEnter={onHoverIn} onMouseLeave={onHoverOut}
            component={Link} to={to} variant="contained"
            className={classes.buttonClasses}
            disableElevation color="inherit">
            <div className={classes.animContainer} ref={el => setTextRef(el)}>
                {children}
            </div>
            <div className={classes.anim} ref={el => setLogoRef(el)}>
                <LogoC
                    fill={theme.palette.primary.light} className={classes.arrow} />
            </div>

        </Button>

    );
}
CoursesCards.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    classes: PropTypes.shape({}),
}
export default withStyles(styles)(CoursesCards)
