
const styles = (theme) => ({
  buttonClasses: {
    fontFamily: "Lovelo",
    fontSize: "1rem",
    width: 200,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
      width: 300,
    },

    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      // color: theme.palette.secondary.light,
      // animationName: "$buttonAnimation",
      animationFillMode: "forwards",
      animationDuration: "0.3s",
      animationDirection: "alternate",
    },
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // flexWrap: "nowrap",
    // fontSize: "1rem",

    // [theme.breakpoints.up("sm")]: {
    //   fontSize: "1.5rem",
    //   width: 300,
    // },
  },
  arrow: {
    transform: "rotate(90deg)",
    height: theme.spacing(2.5),
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(3.2),
    },
  },
  animContainer: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",

  },
  anim: {
    display: "none",
    alignSelf: "center",
    justifySelf: "flex-end",
    justifyContent: "flex-end",
    alignItems: "center",
    opacity: "0%",
  },
  hidden: {
    display: "hidden",
  },
  // "@keyframes buttonAnimation": {
  //   "0%": {
  //     backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 0%, ${theme.palette.common.white} 100%)`,
  //   },
  // "10%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 10%, ${theme.palette.secondary.main} 100%)`,
  // },
  // "20%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 20%, ${theme.palette.secondary.main} 100%)`,
  // },
  // "30%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 30%, ${theme.palette.secondary.main} 100%)`,
  // },
  // "40%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 40%, ${theme.palette.secondary.main} 100%)`,
  // },
  // "50%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 50%, ${theme.palette.secondary.main} 100%)`,
  // },
  // "60%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 60%, ${theme.palette.secondary.main} 100%)`,
  // },
  // "70%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 70%, ${theme.palette.secondary.main} 100%)`,
  // },
  // "80%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 80%, ${theme.palette.secondary.main} 100%)`,
  // },
  // "90%": {
  //   backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 90%, ${theme.palette.secondary.main} 100%)`,
  // },
  //   "100%": {
  //     backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 100%, ${theme.palette.primary.main} 100%)`,
  //   },
  // },
});

export default styles;
